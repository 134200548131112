/* Sponsors.css */

.sponsors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px; /* Reduced gap for closer images */
    /* padding: 20px; */
    justify-items: space-aroundstart;
    align-items: center;
    height: 10px;
    margin-bottom: 100px;
  }
  
  .sponsors-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px; /* Adjust the height as needed */
    margin: 0; /* Ensure there's no extra margin */
    /* background-color: yellow; */

  }
  
  .sponsors-logo img {
    max-width: 100px; /* Adjust the width as needed */
    height: auto;
  }
  .supported-by {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
  }
  .media-partners {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
  }

  .supported-by-mob {
    display: none;
  }
  .sponsor-logo-mob {
    display: none;
  }

  .sponsor-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  .media-mob {
    display: none;
  }
  .media-des {
    display: block;
  }
  .banner-gleams {
    font-size: 120px;
    margin-bottom: 10px;
  }
  .banner-awards {
    font-size: 120px;  
    margin-bottom: 30px;
  }

  @media (max-width: 576px) {
    .supported-by {
      display: none;
    }
    .sponsor-logo {
      display: none;
    }
    .supported-by-mob {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: aqua; */
      margin-top: 30px;
    }
    .sponsor-logo-mob {
      display: block;
    }
  }

  @media (max-width: 780px) {
    .media-mob {
      display: block;
    }
    .media-des {
      display: none;
    }
  }